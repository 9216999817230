<template>
	<div class="body-right pa-5">
		<div class="body-1 mb-2">Результаты</div>
		<span v-if="!publish" class="subtitle-2 font-weight-light">
			Опубликуйте голосование для просмотра результатов
		</span>
		<div v-else class="analytic">
			<h1 class="text-caption mb-2">{{ question.title }}</h1>
			<h4 class="subtitle-2">{{ `Всего: ${all}` }}</h4>
			<h4 class="subtitle-2 green--text">{{ `За: ${yes}` }}</h4>
			<h4 class="subtitle-2 red--text">{{ `Против: ${no}` }}</h4>
			<h4 class="subtitle-2 grey--text">{{ `Воздержусь: ${refrain}` }}</h4>
			<!-- Controls -->
			<div class="controls mt-2">
				<v-btn icon small @click="decrement">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<span class="subtitle-2">{{
					`${qIndex + 1} / ${questions.length}`
				}}</span>
				<v-btn icon small @click="increment">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
			<!-- Результат -->
			<div class="d-flex flex-column mt-5">
				<h1 class="body-1 mb-1">Решение</h1>
				<span v-if="!question.decision">
					<v-btn
						class="subtitle-2 text-capitalize"
						color="primary"
						depressed
						x-small
						outlined
						@click="showDecisionModal = true"
					>
						Отправить
					</v-btn>
				</span>
				<span v-else class="subtitle-2 font-weight-light" style="overflow-wrap: anywhere;">
					{{ question.decision }}
				</span>
			</div>
			<h2 class="body-1 mb-1 mt-2">Публикация протокола</h2>
			<v-btn
				v-if="!protocol"
				class="subtitle-2 text-capitalize"
				color="primary"
				depressed
				x-small
				outlined
				@click="getProtocol"
			>
				Скачать
			</v-btn>
			<v-btn
				v-else
				class="subtitle-2 text-capitalize"
				color="primary"
				depressed
				x-small
				outlined
				:href="getSource(protocol)"
				target="_blank"
			>
				ОТКРЫТЬ
			</v-btn>
			<div>
				<v-progress-circular class="ml-2 mt-3" indeterminate color="primary" v-show="loading"></v-progress-circular>
			</div>
			<h2 class="body-1 mb-1 mt-2">Архив листов голосования</h2>
			<v-btn
				class="subtitle-2 text-capitalize"
				color="primary"
				depressed
				x-small
				outlined
				@click="getZip"
			>
				Скачать
			</v-btn>
		</div>
		<VoteItemInfoDecisionModal
			v-if="showDecisionModal"
			:question="question"
			@close-modal="closeDecisionModal"
		/>
	</div>
</template>

<script>
import VoteItemInfoDecisionModal from "@/components/VoteItemInfoDecisionModal";

export default {
	name: "VoteItemInfoRight",
	components: { VoteItemInfoDecisionModal },
	props: {
		publish: {
			type: Boolean,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
		voteId: {
			required: true,
		},
	},
	data() {
		return {
			qIndex: 0,
			showDecisionModal: false,
			protocol: null,
			zip: null,
			loading: false,
		};
	},
	computed: {
		question() {
			return this.questions[this.qIndex];
		},
		yes() {
			const { count, percent } = this.question.analytic.yes;
			return `${count} (${percent}%)`;
		},
		no() {
			const { count, percent } = this.question.analytic.no;
			return `${count} (${percent}%)`;
		},
		all() {
			const { count, percent } = this.question.analytic.all;
			return `${count} (${percent}%)`;
		},
		refrain() {
			const { count, percent } = this.question.analytic.refrain;
			return `${count} (${percent}%)`;
		},
	},
	methods: {
		getSource(file) {
			return `${process.env.VUE_APP_API_URL}/${file.path}`;
		},
		async getProtocol() {
			try {
				this.loading = true;
				const response = await this.$api.ksk.get_protocol(this.id, {
					publish: true,
				});
				this.protocol = response
				this.$root.snackbar.show({ color: "success", message: this.protocol.success});
				this.loading = false;
			} catch (error) {
				this.$root.snackbar.show({ color: "danger", message: error.message});
			}
		},
		async getZip() {
			try {
				this.loading = true;
				const response = await this.$api.ksk.get_zip(this.id, {
					publish: true,
				});
				const blob = new Blob([response], { type: 'application/zip' });
				const url = window.URL.createObjectURL(blob);

				const link = document.createElement('a');
				link.href = url;
				link.download = this.voteId ? `Голосование №${this.voteId}` : 'file.zip';
				link.click();

				this.$root.snackbar.show({ color: "success", message: 'Успешно'});
				this.loading = false;
			} catch (error) {
				console.log('error', error)
				if(error.error.response.status == 422) {
					return this.$root.snackbar.show({ color: "danger", message: "Отсутствуют подписанные листы голосования"});
				}
				return this.$root.snackbar.show({ color: "danger", message: error.message});
			}
		},

		closeDecisionModal(isConfirmed, decision) {
			this.showDecisionModal = false;

			if (!isConfirmed) return;

			this.question.decision = decision;
		},

		increment() {
			if (this.qIndex < this.questions.length - 1) this.qIndex++;
		},
		decrement() {
			if (this.qIndex > 0) this.qIndex--;
		},
	},
};
</script>
