<template>
	<v-dialog v-model="dialog" width="500" persistent>
		<v-card>
			<v-card-title class="text-body-1 border-bottom">
				Публикация решения
			</v-card-title>
			<v-card-text class="border-bottom py-5">
				<v-alert v-if="alert" :type="alert.color" dense outlined>
					{{ alert.message }}
				</v-alert>
				<span class="subtitle-2 font-weight-light">Текст решения</span>
				<v-textarea
					v-model="decision"
					class="border-all"
					min-height="100px"
					solo
					v-on:input="check"
					flat
					dense
					:input="check"
					:disabled="loading"
				></v-textarea>
				<p :class="{'red--text': remaining == 0}">{{instruction}}</p>
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="grey white--text"
					depressed
					:disabled="loading"
					@click="closeModal(false)"
				>
					Отмена
				</v-btn>
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:disabled="disabled"
					:loading="loading"
					@click="saveDecision"
				>
					Отправить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "VoteItemInfoDecisionModal",

	props: {
		question: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			limit: 500,
			alert: null,
			dialog: true,
			decision: "",
			loading: false,
		};
	},

	computed: {
		disabled() {
			return this.decision.length == this.limit;
		},
		instruction() {  
			return this.decision==''?
				'максимум: '+this.limit+' символов':
				'осталось '+this.remaining+' символов';      
    },
    remaining() {
      return this.limit-this.decision.length;
    }
	},

	methods: {
		check() {
      this.decision = this.decision.substr(0, this.limit)
    },
		async saveDecision() {
			this.alert = null;

			try {
				this.loading = true;

				const formData = new FormData();

				formData.append("title", this.question.title);
				formData.append("description", this.question.description);
				formData.append("decision", this.decision);

				const res = await this.$api.ksk.edit_vote_question(
					this.question._id,
					formData
				);

				console.log(res);

				this.alert = {
					color: "success",
					message: "Решение отправлено",
				};

				setTimeout(() => this.closeModal(true), 500);
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка",
				};
			} finally {
				this.loading = false;
			}
		},

		closeModal(confirm = false) {
			this.dialog = false;
			this.$emit("close-modal", confirm, this.decision);
		},
	},
};
</script>
