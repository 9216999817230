<template>
  <div class="d-flex relative">
    <v-chip-group
      class="rounded-lg pa-1 mt-2"
      :class="[{ 'border-all': borderAll, 'disabled-item': disabled && inModal }, showDropdown ? 'dropdown' : '']"
      :active-class="disabled && inModal ? '' : 'primary primary--text'"
      :disabled="disabled"
      :show-arrows="false"
      v-model="activeChips"
      multiple
      :column="showDropdown"
    >
      <v-chip
        :outlined="!disabled || !inModal"
        :class="{'pointer-none': disabled}"
        class="px-5 mr-4"
        v-for="(chip, index) in chipsCopy"
        :key="chip.name"
        :input-value="isActive(index)"
        @click="toggleChip(index)"
      >
        {{ chip.name }}
      </v-chip>
    </v-chip-group>
    <!-- Стрелка выпадающего списка -->
    <v-btn
      v-if="showArrow"
      :class="showDropdown ? 'dropdown' : ''"
      class="absolute"
      icon
      @click="toggleDropdown"
    >
      <v-icon>mdi-chevron-down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ChipsGroup',
  props: {
    // INDEX
    activeChip: {
      type: Array,
      default: () => [],
    },
    // CATEGORY_ID
    activeChipID: {
      type: Array,
      default: () => [],
    },
    chips: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    borderAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      applyChanging: -1,
      chipsCopy: [],
      showDropdown: false,
      activeChips: [],
    };
  },
  methods: {
    // Toggle chip selection
    toggleChip(index) {
      const chipId = this.chipsCopy[index].id;
      const chipIndex = this.activeChips.indexOf(chipId);

      if (chipIndex !== -1) {
        this.activeChips.splice(chipIndex, 1);
      } else {
        this.activeChips.push(chipId);
      }
      this.updateActiveChip();
    },
    isActive(index) {
      const chipId = this.chipsCopy[index].id;
      return this.activeChips.includes(chipId);
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    updateActiveChip() {
      const activeChipIDs = this.activeChips.map(chipId => {
        const chip = this.chips.find(chip => chip.id === chipId);
        return chip ? chip.id : null;
      }).filter(id => id !== null);

      this.$emit('update:activeChip', this.activeChips);
      this.$emit('update:activeChipID', activeChipIDs);
    },
  },
  mounted() {
    this.chipsCopy = [...this.chips];
    this.activeChips = [...this.activeChip];
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__next,
::v-deep .v-slide-group__prev {
  display: none !important;
}

::v-deep .v-chip-group .v-slide-group__content {
  padding: 0 !important;
}

::v-deep .v-chip-group:not(.dropdown) .v-slide-group__content {
  flex: 0 auto !important;
  max-height: 40px !important;
  white-space: wrap !important;
}
.absolute {
  position: absolute;
  z-index: 2;
  top: 17px;
  right: 10px;
}
.absolute.dropdown {
  transform: rotate(180deg);
}
.relative {
  position: relative;
}
.pointer-none {
  pointer-events: none;
}
</style>

<style>
.disabled-item .v-chip:not(.v-chip--active) {
  background-color: #fff !important;
}
.disabled-item .v-chip--active {
  background: #E6E6E6 !important;
}
.disabled-item .v-chip {
  color: #B5B9BD !important;
  border: 1px solid #B5B9BD !important;
}
</style>