<template>
	<div class="vote-item-list d-flex flex-column">
		<div class="border d-flex justify-space-between align-center py-5 px-3">
			<v-btn
				class="pa-0"
				link
				depressed
				plain
				@click="$router.push({ path: '/vote-new', query: $route.query })"
			>
				<v-icon color="grey darken-1" small>mdi-chevron-left</v-icon>
				<span class="caption">Список</span>
			</v-btn>
			<v-btn
				class="pa-0 grey--text text-lowercase"
				depressed
				plain
				@click="resetFilters"
			>
				<span class="caption">
					<span class="text-capitalize mr-1">
						Сбросить
					</span>
					фильтры
				</span>
			</v-btn>
		</div>

		<!-- Спиннер для загрузки новых записей  -->
		<div
			v-if="loadingMore || loading"
			class="d-flex justify-center"
			:class="{
				'h-100 border-right flex-sm-grow-1 overflow-y-auto': loading,
			}"
		>
			<v-progress-linear
				absolute
				indeterminate
				color="green"
			></v-progress-linear>
		</div>

		<!-- Записи -->
		<div
			class="list border-right d-flex flex-column align-center pa-3"
			@scroll="onScroll"
		>
			<VoteItemListCard
				v-for="item of items"
				:key="item.id"
				:item="item"
				:id="item.id"
				@change-page="changePage(item)"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "@/components/app/DatePicker";
import VoteItemListCard from "@/components/votes/VoteItemListCardNew";

export default {
	name: "VoteItemList",
	components: {
		DatePicker,
		VoteItemListCard,
	},
	data() {
		return {
			items: [],
			lastPage: null,
			loading: false,
			loadingMore: false,
			scrolledPages: [],
			page: +this.$route.query.page || 1,
		};
	},
	computed: {
		...mapGetters({
			kskId: "auth/GET_KSK_ID",
		}),
	},
	methods: {
		changePage(item) {
			this.$router.replace({ query: { page: item.page } });
		},

		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			const firstPage = this.items[0].page;
			const lastPage = this.items[this.items.length - 1].page;

			if (
				scrollTop + clientHeight >= scrollHeight &&
				lastPage <= this.lastPage
			) {
				this.loadData();
			} else if (scrollTop === 0 && firstPage - 1 >= 1) {
				this.loadData(false);
			}
		},

		scrollIntoView(id) {
			document.getElementById(id).scrollIntoView({
				behavior: "smooth",
			});
		},

		resetFilters() {
			this.page = 1;
			this.scrolledPages = [];
			this.$router.replace({ query: { page: 1 } });
			this.loadData(undefined, true);
		},

		// https://stackoverflow.com/questions/59603315/how-to-detect-when-a-user-scrolls-to-the-bottom-of-a-div-vue

		async loadData(toDown = true, update = false) {
			try {
				let newPage;

				if (update) newPage = this.page;
				else if (toDown) newPage = this.items[this.items.length - 1].page + 1;
				else newPage = this.items[0].page - 1;

				if (this.scrolledPages.includes(newPage)) {
					return;
				}

				if (update) {
					this.loading = true;
				} else {
					this.loadingMore = true;
				}

				const res = await this.$api.ksk.load_votes_new({
					...this.$route.query,
					page: newPage,
					sort_type: 'DESC',
					selected_ksk_id: this.kskId,
					company_ids: [this.kskId]
				});

				res.data = res.data.map(e => ({ ...e, page: newPage }));
				this.lastPage = res.pages;

				if (update) {
					this.items = [...res.data];
				} else if (toDown) {
					this.scrolledPages.push(newPage);
					this.items = [...this.items, ...res.data];
				} else {
					this.scrolledPages.unshift(newPage);
					this.items = [...res.data, ...this.items];
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = this.loadingMore = false;
			}
		},
	},
	async created() {
		await this.loadData(undefined, true);

		if (this.items.length < 10) {
			if (this.page - 1 >= 1) {
				this.page--;
				await this.loadData(false);
			}
		}
		// const { _id } = this.items.find(e => e._id === this.$route.params.id);
		// setTimeout(() => {
		// 	this.scrollIntoView(_id);
		// }, 100);
	},
};
</script>

<style lang="scss" scoped>
.vote-item-list {
	height: 100vh;
	position: relative;
	overflow: hidden;
}
.h-100 {
	height: 100vh;
}
.list {
	flex: 1;
	overflow: scroll;
}
</style>
