<template>
	<div class="vote-item" ref="item">
		<VoteItemList ref="list" />
		<VoteItemInfo @update-list="updateList" />
	</div>
</template>

<script>
import VoteItemList from "@/components/votes/VoteItemListNew";
import VoteItemInfo from "@/components/votes/VoteItemInfoNew";

export default {
	name: "VoteItem",
	components: {
		VoteItemList,
		VoteItemInfo,
	},
	data() {
		return {};
	},
	methods: {
		updateList() {
			this.$refs.list.loadData(undefined, true);
		},
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.vote-item {
	height: max-content;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	display: grid;
	grid-template-columns: 1fr 4fr;
	overflow: hidden;
}
</style>
