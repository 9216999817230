<template>
  <div class="custom-file-input" @click="triggerFileInput" :class="{'pointer-none': disabled}">
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      :accept="accept"
      multiple
      style="display: none;"
    />
    <div class="file-input-label">
      <v-icon class="attachment-icon" :class="{'disabled-item': disabled}">mdi-paperclip</v-icon>
      <span class="label custom-dark segoeui-dark" :class="{'disabled-item': disabled}">Прикрепить</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png, .xlsx, .xls, .pdf, .doc, .docx, .pptx, .ppsx, .odp'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleFileChange(event) {
      const files = event.target.files;
      this.$emit('change', files);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    }
  }
};
</script>

<style scoped>
.custom-file-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.file-input-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachment-icon {
  font-size: 26px;
  transform: rotate(45deg);
  color: #2EB85C;
}

.label {
  margin-left: 8px;
  font-size: 14px;
}

.pointer-none {
  pointer-events: none;
}
.disabled-item {
  color: #B5B9BD !important;
}
</style>
